import { default as contactFormsV5Yvj455RMMeta } from "/app/pages/cms/contactForms.vue?macro=true";
import { default as indexKFKRyElj8jMeta } from "/app/pages/cms/index.vue?macro=true";
import { default as newsArticlesCdT27qpsbWMeta } from "/app/pages/cms/newsArticles.vue?macro=true";
import { default as cmshz0XNCpo4eMeta } from "/app/pages/cms.vue?macro=true";
import { default as cmsAuthJlr0E2FGUUMeta } from "/app/pages/cmsAuth.vue?macro=true";
import { default as contactUssMQ6gX1U2qMeta } from "/app/pages/contactUs.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: cmshz0XNCpo4eMeta?.name,
    path: "/cms",
    component: () => import("/app/pages/cms.vue").then(m => m.default || m),
    children: [
  {
    name: "cms-contactForms",
    path: "contactForms",
    meta: contactFormsV5Yvj455RMMeta || {},
    component: () => import("/app/pages/cms/contactForms.vue").then(m => m.default || m)
  },
  {
    name: "cms",
    path: "",
    meta: indexKFKRyElj8jMeta || {},
    component: () => import("/app/pages/cms/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-newsArticles",
    path: "newsArticles",
    meta: newsArticlesCdT27qpsbWMeta || {},
    component: () => import("/app/pages/cms/newsArticles.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cmsAuth",
    path: "/cmsAuth",
    meta: cmsAuthJlr0E2FGUUMeta || {},
    component: () => import("/app/pages/cmsAuth.vue").then(m => m.default || m)
  },
  {
    name: "contactUs",
    path: "/contactUs",
    component: () => import("/app/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]