// Referencing: https://notes.atinux.com/nuxt-custom-fetch

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()

    const $customFetch = $fetch.create({
      baseURL: config.public.baseURL,

      onRequestError(_ctx) {
        console.error('onRequestError Error:');
        console.error(_ctx);
      },
  
      onResponseError(_ctx) {
        console.error('onResponseError Error:');
        console.error(_ctx);
        // throw createError(_ctx.response)
        // throw new myBusinessError()
        // throw createError({
        //   _ctx
        //   // ...error.value,
        //   // statusMessage: `Could not fetch data from ${url}`,
        // });
      },
    })

    
    // Expose to useNuxtApp().$customFetch
    return {
      provide: {
        customFetch: $customFetch
      }
    }
  })