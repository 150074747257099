import * as Sentry from "@sentry/vue";
export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const {
        public: { sentry },
    } = useRuntimeConfig();
    if (!sentry.dsn) {
        return;
    }
    if(process.env.NODE_ENV === 'production'){
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: sentry.dsn,
            environment: sentry.environment,
            // integrations: [
            //     new Sentry.BrowserTracing({
            //         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            //     }),
            //     new Sentry.Replay(),
            // ],
            integrations: [
                Sentry.browserTracingIntegration({router}),
                Sentry.replayIntegration(),
              ],
            // Configure this whole part as you need it!
            tracesSampleRate: 1.0, // Change in prod
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // process.env.NODE_ENV === 'production'
            // tracePropagationTargets: ["localhost", "https://demo.api.kevinlabmy.com", "https://live.api.kevinlabmy.com"],
            replaysSessionSampleRate: 1.0, // Change in prod
            replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    
            //   // Performance Monitoring
            //   tracesSampleRate: 1.0, // Capture 100% of the transactions
            //   // Session Replay
            //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    
            ignoreErrors: [
                "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
                "Cannot read properties of null (reading '_leaveCb')",
                "Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.", // chromium based
                "NotFoundError: The object can not be found here.", // safari
                "NotFoundError: Node.insertBefore: Child to insert before is not a child of this node", // firefox
            ],
        });
    }
});