import {ClickOutside} from 'vuetify/directives/click-outside'
import {Resize} from 'vuetify/directives/resize'
import {Ripple} from 'vuetify/directives/ripple'
import {VDialog} from 'vuetify/components/VDialog'
import {VExpansionPanel,VExpansionPanelText,VExpansionPanelTitle} from 'vuetify/components/VExpansionPanel'
import {VBadge} from 'vuetify/components/VBadge'
import {VDateInput} from 'vuetify/labs/VDateInput'

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"defaults":{"VAppBar":{"elevation":0},"VBtn":{"variant":"flat"},"VCard":{"color":"rgba(var(--v-theme-cardBg), var(--v-high-emphasis-opacity))"},"VProgressLinear":{"color":"primary"},"VProgressCircular":{"color":"primary"}},"theme":{"defaultTheme":"lightTheme","themes":{"lightTheme":{"dark":false,"colors":{"background":"#ffffff","onBackground":"#26274b","cardBg":"#ffffff","cardBg2":"#fcfcfc","cardBg3":"#fcfcfc","cardBg4":"#fcfcfc","cardPrimary":"#FCFCFC","cardAccent":"#606770","textAccent":"#606770","highlightPrimary":"#2B2B2B","onHighlight":"#FCFCFC","primary":"#272727","onPrimary":"#EFF0F0","secondary":"#EFF0F0","info":"#039BE5","warning":"#FB8C00","error":"#E53935","success":"#26b388","greyish":"#C4C4C4"}},"darkTheme":{"dark":true,"colors":{"background":"#101011","onBackground":"#FCFCFC","cardBg":"#1A1D1F","cardBg2":"#16181A","cardBg3":"#26262c","cardBg4":"#2c2e33","cardPrimary":"#272B30","cardAccent":"#606770","textAccent":"#787b8b","highlightPrimary":"#6D767F","onHighlight":"#FCFCFC","primary":"#455DDC","onPrimary":"#606770","secondary":"#7945DC","info":"#1E88E5","warning":"#F57C00","error":"#E53935","success":"#26b388","greyish":"#464c59"}}},"variations":{"colors":["primary","secondary"],"lighten":3,"darken":3}}}
  options.directives = {ClickOutside,Resize,Ripple}
  options.aliases = {'MyBadge': VBadge}
  options.components = {VDialog,VExpansionPanel,VExpansionPanelText,VExpansionPanelTitle,VDateInput}
  
  return options
}

